.Main {
  display: flex;
  background: #0e0e0e;
  min-height: 100vh;
  max-height: 100vh;
  font-family: dapifer, serif;
  font-weight: 700;
  font-style: normal;
  // overflow-x: hidden;
}

.button1 {
  display: none;
}

.container {
  // margin-top: 10px;
  position: relative;
  justify-content: center;
  width: 100%;
  background: #0e0e0e;

  h2 {
    font-size: 45px;
    color: white;
    margin-top: 10px;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
  }
  img {
    margin: auto;
    position: absolute;
    display: block;
    margin-left: 75px;
    // margin-right: auto;
    align-content: flex-end;
    align-items: right;
    width: 65%;
    left: 100px;
    border: 15px solid #373a3f;
    border-radius: 15px;
  }
  span {
    color: white;
    margin-top: -10px;
  }
}
.selector {
  float: right;
  background: #0e0e0e;
  width: auto;
  margin-right: 20px;
  padding-right: 40px;
  //padding-left: 10px;
  .options {
    padding-left: 40px;
  }
  button {
    cursor: pointer;
    background: transparent;
    border: 2px solid wheat;
    border-radius: 10px;
    color: white;
    width: 200px;
    height: 40px;
    margin-top: 20px;
    margin-left: 25px;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
  }
  p {
    cursor: pointer;
    background: transparent;
    border: 2px solid wheat;
    border-radius: 50%;
    color: white;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    float: right;
    margin-right: 20px;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
    .icon {
      margin-top: 10px;
    }
  }
  label {
    color: white;
    float: left;
    margin-top: 15px;
    margin-left: 10px;
  }
  span {
    color: white;
    float: left;
  }
  h2 {
    font-size: 50px;
    color: white;
    margin-bottom: 0px;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
  }
  select {
    width: 300px;
    height: 40px;
    border-radius: 9px;
    padding-left: 10px;
    margin-top: 4px;
    background: #f0cd6f;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
  }
}
.Forge {
  float: left;
  background: #f0cd6f;
  width: auto;
  height: auto;
  margin-bottom: 0px;
  button {
    p {
      // writing-mode: vertical-rl;
      text-align: center;
      margin-left: 0px;
      color: white;
      font-size: 17px;
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
    }
    cursor: pointer;
    display: block;
    background-color: #0e0e0e;
    border: 1px solid rgb(150, 148, 148);
    width: 200px;
    height: 230px;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-left: 20px;
    border-radius: 15px;
    // transform: rotate(180deg);
  }
  h2 {
    font-size: 40px;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    margin-bottom: 0px;
  }
  .image {
    background: #0e0e0e;
  }
  .uploadbtn {
    margin-right: 10px;

    .button1 {
      // display: none;
    }
  }
  .last {
    border: 1px solid #373a3f;
    background: #0e0e0e;
    width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .second {
    display: flex;
  }
  .upload {
    width: 200px;
    height: 200px;
    margin: 20px;
  }
  .middiv {
    padding: 15px;
  }
}

/*
*****Mobile Media Queries******
*****Mobile Media Queries******
*****Mobile Media Queries******
*****Mobile Media Queries******
*****Mobile Media Queries******
*/

@media screen and (max-width: 600px) {
  .Main {
    display: flex;
    flex-direction: column;
    background: #0e0e0e;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    font-family: dapifer, serif;
    font-weight: 700;
    font-style: normal;
  }

  .container {
    position: relative;
    justify-content: center;
    background: #0e0e0e;

    h2 {
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
      font-size: 30px;
      color: white;
    }
    img {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      align-content: right;
      align-items: center;
      width: 65%;
      left: 50px;
      border: 15px solid #373a3f;
      border-radius: 15px;
    }

    span {
      color: white;
    }
  }
  .selector {
    background: #0e0e0e;
    width: 100%;

    .options {
      width: 80%;
      column-count: 2;
      column-gap: 10px;
      height: 225px;
    }

    button {
      cursor: pointer;
      background: transparent;
      border: 2px solid wheat;
      border-radius: 10px;
      color: white;
      width: 160px;
      height: 40px;
      margin-top: 15px;
      display: none;
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
    }
    p {
      cursor: pointer;
      // margin-right: auto;
      margin-left: 400px;
      margin-top: 15px;
      background: transparent;
      border: 2px solid wheat;
      border-radius: 50%;
      color: white;
      width: 45px;
      height: 40px;
      .icon {
        margin-top: 10px;
      }
    }
    label {
      display: none;
      flex-wrap: wrap;
      color: white;
      font-size: 16px;
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
      align-items: left;
      margin-top: 25px;
      margin-left: 10px;
    }
    span {
      color: white;
      float: left;
    }
    h2 {
      margin: auto;
      margin-top: 300px;
      font-size: 26px;
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
      align-items: center;
      color: white;
      margin-bottom: 0px;
    }
    select {
      width: 140px;
      height: 50px;
      border-radius: 9px;
      font-size: 14px;
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
      margin-top: 4px;
      background: #f0cd6f;
    }
  }
  .Forge {
    background: #f0cd6f;
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    button {
      p {
        writing-mode: horizontal-rl;
        align-items: center;
        text-align: center;
        margin: auto;
        color: white;
        font-size: 15px;
        font-family: dapifer, serif;
        font-weight: 700;
        font-style: normal;
      }
      cursor: pointer;
      display: block;
      background-color: #0e0e0e;
      border: 1px solid rgb(150, 148, 148);
      width: 175px;
      height: 200px;
      margin: auto;
      margin-top: 20px;
      border-radius: 15px;
      padding: 50px;
      // transform: rotate(180deg);
    }
    h2 {
      font-size: 20px;
      font-family: dapifer, serif;
      font-weight: 700;
      font-style: normal;
      text-align: center;
      margin-bottom: 0px;
    }
    .image {
      background: #373a3f;
    }
    .uploadbtn {
      margin-right: 10px;
    }
    .last {
      border: 1px solid #373a3f;
      background: #0e0e0e;
      width: 250px;
      height: 450px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
    }
    .second {
      // display: flex;
      position: relative;
      // height: fit-content;
    }
    .upload {
      width: 200px;
      height: 200px;
      margin: 20px;
    }
    .middiv {
      // position: relative;
      // margin: auto;
      padding: 15px;
      width: auto;
      min-height: 100%;
      height: 600px;
      // height: 100%;
    }
  }
}
